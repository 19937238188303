<template>
  <div>
    <div class="tabs-area">
      <el-tabs v-model="activeName">
        <el-tab-pane label="我的呼叫统计" name="me"></el-tab-pane>
        <el-tab-pane label="下级呼叫统计" name="subordinate"></el-tab-pane>
        <el-tab-pane label="全部呼叫统计" name="third"></el-tab-pane>
      </el-tabs>
    </div>
    <Tabs v-model="tabValue" @tabChange="tabChange" />
    <page-header ref="pageHeader" @search="search" />

    <!-- <div class="quick-queries">
        <span>快捷查询</span>
        <span class="queries-item" 
        :class="{'queries-active':isQueries}"
         @click="quickQueries">
            呼入队列放弃 ({{queueCount}})
        </span>
    </div> -->

    <div class="table">
      <div class="btn">
        <el-button type="primary" size="small" @click="exportTable" icon="el-icon-download">
          导出
        </el-button>
        <div>
          <span class="static-item" v-for="(item, index) in staticList" :key="index">
            <span>{{ item.title }}</span>
            <span>{{ item.value }}</span>
          </span>
        </div>
        <!-- <el-button size="small" @click="toggleSelection('all')">全选</el-button>
        <el-button size="small" @click="toggleSelection()">反选</el-button> -->
      </div>
      <Table
        ref="table"
        :table-data="tableData"
        @handleSelectionChange="handleSelectionChange"
        :form="form"
        :total="total"
        :loading="loading"
        @reload="getTableData"
      />
      <Pages
        :total="total"
        :current-page="form.pageNumber"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import pageHeader from './header'
import Table from './table'
import { getPhoneList, callcenterExportList } from '@/api'
import { downloadFile } from '@/utils/fileDownload'
import { getCallStaticList } from '@/api/callRecord'
import Tabs from './tabs.vue'
import Pages from '@/components/pages'
export default {
  components: {
    pageHeader,
    Table,
    Pages,
    Tabs,
  },
  data() {
    return {
      tableData: [],
      total: 0,
      multipleSelection: [],
      form: {
        pageSize: 10,
        pageNumber: 1,
        data: {},
      },
      loading: false,
      staticList: [],
      activeName: 'me',
      queryPermissionsFlagObj: {
        me: 1,
        subordinate: 2,
        third: 3,
      },
      isQueries: false,
      queueCount: 0,
      tabValue: 0,
    }
  },
  watch: {
    activeName: {
      handler(val) {
        this.tabValue = 0
        this.$refs.pageHeader.reset()
      },
      immediate: true,
    },
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    tabChange(value) {
      this.$refs.pageHeader.reset()
    },

    search(data) {
      this.form.pageNumber = 1
      this.form.data = { ...data }
      this.form.data.callResultType = this.tabValue || ''
      this.getTableData()
    },
    getTableData() {
      const queryPermissionsFlag = this.queryPermissionsFlagObj[this.activeName]
      this.form.data.queryPermissionsFlag = queryPermissionsFlag
      this.loading = true
      getPhoneList(this.form).then((res) => {
        this.loading = false
        this.tableData = res.data.content
        this.total = Number(res.data.total)
      })

      getCallStaticList(this.form).then((res) => {
        this.staticList = res.data
      })
    },
    toggleSelection(rows) {
      this.$refs.table.toggleSelection(rows)
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    exportTable() {
      if (this.multipleSelection.length === 0) {
        this.$message('请至少选择一条数据')
        return
      }
      const data = []
      this.multipleSelection.forEach((e) => {
        data.push(e.logId)
      })
      this.loading = true
      callcenterExportList({ data }).then((res) => {
        this.loading = false
        downloadFile(res, '呼叫记录')
      })
    },
    getStatisticsDate() {},
    quickQueries() {
      this.isQueries = !this.isQueries
    },
  },
}
</script>
<style lang="less" scoped>
.table {
  margin-top: 0;
}
.btn {
  display: flex;
  justify-content: space-between;
}
.static-item {
  margin-right: 24px;
  font-size: 13px;

  &:last-child {
    margin-right: 0;
  }

  span:first-child {
    color: #9a9d9e;
    margin-right: 4px;
  }

  span:last-child {
    color: #333333;
    font-weight: 500;
  }
}
.tabs-area {
  background: #fff;
  padding: 16px 20px 0;
}

.quick-queries {
  background: #fff;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;

  span:first-child {
    color: #9a9d9e;
    margin-right: 16px;
  }
  .queries-item {
    color: #000000;
    display: inline-block;
    border-radius: 2px 2px 2px 2px;
    padding: 6px 10px;
    cursor: pointer;
  }
  .queries-active {
    background: rgba(40, 97, 226, 0.12);
    color: #2861e2;
  }
}
::v-deep {
  .el-button--primary {
    background: #2861e2;
    border-color: #2861e2;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: #5381e8;
    border-color: #5381e8;
    color: #fff;
  }
}
</style>
