<template>
  <div class="Tabs">
    <div class="items">
      <div
        class="item"
        :class="{ active: item.value === sclectValue }"
        v-for="item in opthos"
        :key="item.value"
        @click="sclect(item.value)"
      >
        {{ item.lable }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
    },
  },
  data() {
    return {
      opthos: [
        { lable: "全部呼叫记录", value: 0 },
        { lable: "通话记录", value: 1 },
        { lable: "未接来电", value: 2 },
      ],
      sclectValue: this.value,
    };
  },
  watch:{
    value(val){
        this.sclectValue = val
    },
  },
  methods: {
    sclect(value) {
      this.sclectValue = value;
      this.$emit("input", value);
      this.$emit("tabChange", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.Tabs {
  padding: 0 20px 18px;
  background: #fff;

  .items {
    display: inline-flex;
    background: #f4f4f4;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    color: #303133;
    padding: 2px;
    .item {
      padding: 8px 20px;
      cursor: pointer;
      &:hover {
        color: #2861e2;
      }
    }
    .active {
      font-weight: 500;
      color: #2861e2;
      border-radius: 2px 2px 2px 2px;
      background: #ffffff;
    }
  }
}
</style>
