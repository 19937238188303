<template>
  <div class="header" style="padding-top: 0">
    <el-form :model="form" class="demo-form-inline">
      <el-row :gutter="32">
        <el-col :span="6">
          <div class="search-item">
            <span class="search-item-label">拨打人</span>
            <el-input size="small" v-model="form.staffName" placeholder="请输入关键字"></el-input>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="search-item">
            <span class="search-item-label">拨打号码</span>
            <el-input v-model="form.custispName" size="small" placeholder="请输入号码"></el-input>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="search-item">
            <span class="search-item-label">类型</span>
            <el-select size="small" v-model="form.callType" placeholder="请选择" clearable>
              <el-option label="呼入" value="1"> </el-option>
              <el-option label="呼出" value="2"> </el-option>
              <el-option label="自动外呼" value="3"> </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="search-item">
            <span class="search-item-label">状态查询</span>
            <el-select size="small" v-model="form.callResult" placeholder="请选择" clearable>
              <el-option
                v-for="(n, i) in callStatus"
                :key="i"
                :label="n.dictionaryName"
                :value="n.dictionaryValue"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="search-item search-item-time">
            <span class="search-item-label">拨打时间</span>
            <el-date-picker
              v-model="form.time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="small"
            >
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="search-item">
            <span class="search-item-label">标记结果</span>
            <key-select req-key="call_log_sign_status" :val.sync="form.signStatus" />
          </div>
        </el-col>
        <!-- <el-col :span="6">
          <div class="search-item">
            <span class="search-item-label">区域</span>
            <el-select size="small" v-model="form.callResult" placeholder="请选择" clearable>
              <el-option
                v-for="(n, i) in callStatus"
                :key="i"
                :label="n.dictionaryName"
                :value="n.dictionaryValue"
              >
              </el-option>
            </el-select>
          </div>
        </el-col> -->
        <el-col :span="6">
          <div class="search-item">
            <span class="search-item-label">坐席选择</span>
            <el-select
              size="small"
              v-model="form.callAppConfigId"
              placeholder="请选择"
              clearable
              @change="onChangeZx"
            >
              <el-option
                v-for="itemSeats in seats"
                :key="itemSeats.id"
                :label="itemSeats.name"
                :value="itemSeats.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="search-item">
            <span class="search-item-label">呼叫技能组</span>
            <el-select
              size="small"
              v-model="form.groupId"
              placeholder="请选择"
              clearable
              @visible-change="visibleChange"
            >
              <template v-if="changeZx === '1'">
                <el-option
                  v-for="(n, i) in ussGroupList"
                  :key="i"
                  :label="n.group_name"
                  :value="n.group_id"
                >
                </el-option>
              </template>
              <template v-if="changeZx === '2'">
                <el-option
                  v-for="(nn, ii) in ussGroupListYy"
                  :key="ii"
                  :label="nn.skillGroups"
                  :value="nn.skillGroupsId"
                >
                </el-option>
              </template>
            </el-select>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="search-item-handle">
            <el-button type="primary" size="small" @click="onSubmit" icon="el-icon-search">
              查询
            </el-button>

            <el-button size="small" @click="reset" icon="el-icon-refresh-left"> 重置 </el-button>
          </div>
        </el-col>
      </el-row>
      <!-- <el-form-item label="业务ID">
        <el-input
          size="small"
          v-model="form.bizId"
          style="width: 160px"
          placeholder="请输入关键字"
        ></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="客户姓名">
        <el-input
          size="small"
          style="width: 160px"
          placeholder="请输入关键字"
        ></el-input>
      </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
import moment from 'moment'
import { queryChildsById, getUssGroupList, getCallApp, getListByQuery } from '@/api'

export default {
  name: 'PageHeader',
  components: {},
  data() {
    return {
      form: {
        signStatus: '',
        groupId: '',
      },
      fold: false,
      callStatus: [],
      ussGroupList: [],
      ussGroupListYy: [],
      seats: [],
      changeZx: '',
    }
  },
  mounted() {
    queryChildsById({
      data: { key: 'callresult' },
    }).then((res) => {
      this.callStatus = res.data.childs
    })
    this.getUssGroupList()
    this.getCallAppApi()
    this.getListByQuery()
  },
  methods: {
    // 获取呼叫平台-坐席选择
    async getCallAppApi() {
      const res = await getCallApp({ id: '' })
      if (res.success) {
        this.seats = res.data
      }
    },
    // 查询统一技能组列表-优音
    getListByQuery() {
      getListByQuery({
        data: {
          callAppConfigId: '2',
        },
      }).then(({ data }) => {
        this.ussGroupListYy = data
      })
    },
    // 查询统一技能组列表-得助
    getUssGroupList() {
      getUssGroupList().then(({ data }) => {
        this.ussGroupList = data
      })
    },
    onSubmit() {
      const data = { ...this.form }
      if (data.time) {
        data.callTimeStrStartTime = moment(data.time[0]).format('YYYY-MM-DD HH:mm:ss')
        data.callTimeStrEndTime = moment(data.time[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      delete data.time
      this.$emit('search', data)
    },
    reset() {
      this.changeZx = ''
      const { form } = this.$options.data()
      this.form = form
      this.$emit('search', this.form)
    },
    checkFold() {
      this.fold = !this.fold
    },
    onChangeZx(vals) {
      this.changeZx = vals
      this.form.groupId = ''
    },
    visibleChange(e) {
      if (e && !this.changeZx) {
        this.$message.error('请先选择坐席')
      }
    },
  },
}
</script>
<style lang="less" scoped>
.header {
  padding: 0 20px;
}
.search-item {
  display: flex;
  align-items: center;
  background: #f5f6f9;
  border-radius: 4px 4px 4px 4px;
  padding: 0 12px;
  margin-bottom: 20px;

  &-label {
    font-size: 14px;
    color: #999999;
    margin-right: 24px;
    white-space: nowrap;
  }

  /deep/ .el-select {
    width: 100%;
  }
  /deep/ .el-input__inner {
    border: none;
    background: #f5f6f9;
    font-size: 14px;
    padding: 0;
  }

  /deep/ .el-range-input {
    background: #f5f6f9;
    font-size: 14px;
  }

  /deep/ .el-date-editor .el-range-separator {
    width: unset;
    line-height: 32px;
  }

  /deep/ .el-input {
    input::placeholder {
      font-size: 14px;
      color: #999999;
      line-height: 20px;
    }
  }

  /deep/ .el-date-editor {
    justify-content: space-between;

    input::placeholder {
      font-size: 14px;
      color: #999999;
    }
  }

  /deep/.el-select__caret .el-input__icon .el-icon-arrow-up {
    color: #9a9d9e;
  }
}
.search-item-time {
  .item-time-icon {
    flex: 1;
    text-align: right;
    color: #9a9d9e;
    cursor: pointer;
  }
  /deep/ .el-input__icon {
    display: none;
  }
}
.search-item-handle {
  display: flex;
  align-items: center;
}

::v-deep {
  .el-button--primary {
    background: #2861e2;
    border-color: #2861e2;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: #5381e8;
    border-color: #5381e8;
    color: #fff;
  }
}
</style>
