<template>
  <div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      border
      style="width: 100%"
      @selection-change="handleSelectionChange"
      v-loading="loading"
      :header-cell-style="headerCellStyle"
    >
      <el-table-column type="selection" min-width="55"> </el-table-column>
      <el-table-column label="序号" min-width="55">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 + (form.pageNumber - 1) * form.pageSize }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="callType" label="类型" min-width="60">
        <template slot-scope="scope">
          <span>{{
            scope.row.callType == 1
              ? '呼入'
              : scope.row.callType == 2
              ? '呼出'
              : scope.row.callType == 3
              ? '自动外呼'
              : ''
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="groupNameList" label="呼叫技能组" min-width="140">
        <template slot-scope="{ row }">
          <span>{{ (row.groupNameList || []).join() }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="userId" label="坐席ID" min-width="100"> </el-table-column>
      <el-table-column prop="staffName" label="坐席" min-width="100"> </el-table-column>
      <el-table-column prop="callAppConfigName" label="坐席类型" min-width="80"> </el-table-column>
      <el-table-column prop="custispName" label="客户号码" min-width="120"> </el-table-column>
      <el-table-column prop="callTimeStr" label="呼入/呼出时间" min-width="160"> </el-table-column>
      <el-table-column prop="callResultName" label="状态" min-width="140"> </el-table-column>
      <el-table-column prop="signStatusName" label="标记结果" min-width="80"> </el-table-column>
      <el-table-column prop="signResult" label="标记记录" min-width="100" show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="通话时长" min-width="320">
        <template slot-scope="scope">
          <audio v-if="scope.row.voiceUrl" controls="controls" :src="scope.row.voiceUrl"></audio>
          <span v-else>--</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" min-width="60" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="signItem(scope.row)">标记</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="标记呼叫" :visible.sync="callDialogVisible" width="35%">
      <el-form label-position="right" label-width="100px" size="mini">
        <el-form-item label="标记结果：">
          <key-select
            req-key="call_log_sign_status"
            :val.sync="callFormData.signStatus"
            style="width: 100%"
          />
        </el-form-item>

        <el-form-item label="标记记录：">
          <el-input
            v-model="callFormData.signResult"
            :rows="6"
            placeholder="请输入内容"
            show-word-limit
            maxlength="100"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="callDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCallForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updateSignItemStatus } from '@/api/call.js'

export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {},
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectData: [],
      callDialogVisible: false,
      callFormData: {
        logId: '',
        signStatus: '',
        signResult: '',
      },
      headerCellStyle: {
        color: '#909399',
        background: '#F5F7FA',
      },
    }
  },
  methods: {
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v)
      })
    },
    toggleSelection(rows) {
      if (rows) {
        this.$refs.multipleTable.toggleAllSelection()
      } else {
        this.$refs.multipleTable.clearSelection()
        // if (this.selectData.length > 0) {
        //   console.log(this.getArrDifference(this.tableData, this.selectData));
        //   this.getArrDifference(this.tableData, this.selectData).forEach(
        //     (row) => {
        //       this.$refs.multipleTable.toggleRowSelection(row, true);
        //     }
        //   );
        //   this.selectData.forEach((row) => {
        //     this.$refs.multipleTable.toggleRowSelection(row, false);
        //   });
        // }
      }
    },
    handleSelectionChange(val) {
      this.selectData = val
      this.$emit('handleSelectionChange', val)
    },
    signItem(row) {
      this.callDialogVisible = true
      this.callFormData.signStatus = String(row.signStatus || '')
      this.callFormData.signResult = row.signResult || ''
      this.callFormData.logId = row.logId || ''
    },
    submitCallForm() {
      if (!this.callFormData.signStatus) {
        this.$message.warning('请选择标记结果！')
        return
      }

      updateSignItemStatus({ data: this.callFormData })
        .then((res) => {
          if (!res.success) return
          this.$message.success('操作成功！')
          this.$emit('reload')
          this.callDialogVisible = false
        })
        .catch((err) => console.log(err))
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.table_header {
  background: #f5f7fa !important;
}
</style>
